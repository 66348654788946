import { inject, Injectable } from '@angular/core';
import { IRegion, ILanguage } from '../models/header-search-model';
import { HttpClient } from '@angular/common/http';
import { catchError, EMPTY, map, of } from 'rxjs';
import { API_NAME } from '../utils/api-util';
import { AuthService } from '@app/core/services/auth.service';
import { IUserLocalization } from '@layout/models/layout-model';
import { DEFAULT_LANGUAGE, DEFAULT_REGION } from '../utils/layout-util';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly httpClient = inject(HttpClient);
  private readonly authService = inject(AuthService);
  protected appLocalesLanguageList: ILanguage[] = [];
  protected appRegionsDescription: IRegion[] = [];


  getLanguageList() {
    if (this.appLocalesLanguageList.length > 0) {
      return of(this.appLocalesLanguageList);
    }

    return this.httpClient.get<ILanguage[]>(API_NAME.get_all_languages).pipe(
      map((result: any) => {
        if (result as ILanguage[]) {
          this.appLocalesLanguageList = result;
          return result;
        }
        return EMPTY;
      }),
      catchError((error: any) => {
        return EMPTY;
      })
    );
  }

  getRegionList() {
    if (this.appRegionsDescription.length > 0) {
      return of(this.appRegionsDescription);
    }

    return this.httpClient.get<ILanguage[]>(API_NAME.get_all_regions).pipe(
      map((result: any) => {
        if (result as ILanguage[]) {
          this.appRegionsDescription = result;
          return result;
        }
        return EMPTY;
      }),
      catchError((error: any) => {
        return EMPTY;
      })
    );
  }

  getUserLocalization() {
    const userProfile = this.authService.getProfileDetails();
    const mail = userProfile?.email;
    const payload = {
      Email_Address: mail,
    };
    return this.httpClient.post(API_NAME.get_user_location, payload);
  }

  updateUserLocalization(payload: IUserLocalization) {
    return this.httpClient.post(API_NAME.update_region_language, payload);
  }

  getLocalization() {
    const region = sessionStorage?.getItem('localization') as string;
    const regionDetails = JSON?.parse(region);
    return regionDetails as IUserLocalization;
  }
  setLocalization(localization: IUserLocalization) {
    sessionStorage.setItem('localization', JSON?.stringify(localization));
  }

  getLanguage(): string {
    const localization = this.getLocalization();
    return localization?.Preferred_Language
      ? localization?.Preferred_Language
      : DEFAULT_LANGUAGE;
  }
  // setLanguage(language: string) {
  //   sessionStorage.setItem('lang', language);
  // }

  getRegion(): string {
    const localization = this.getLocalization();
    return localization?.Timezone ? localization?.Timezone : DEFAULT_REGION;
  }

  // setRegion(Region: string) {
  //   sessionStorage.setItem('region', Region);
  // }
}
